.wave-data-record-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.6em;
}

.energy {
  width: 4em;
}

.energy-low {
  color: orange;
}

.energy-high {
  color: green;
}

.height-direction {
  width: 10em;
}
