.wave-overview {
    padding: 0.5em;
}

.tooltip-container {
    background-color: #2a2e4f;
    border: solid 1px #9a9dad;
    padding: 0.5em;
    font-weight: bold;
    list-style: none;
}

.tooltip-energy {

}

.tooltip-direction {

}

.tooltip-date {
    font-weight: lighter;
    font-size: smaller;
}

.tooltip-height {
    font-size: 1.1em;
}

.tooltip-low {
    color: red;
}

.tooltip-medium {
    color: orange;
}

.tooltip-high {
    color: green;
}
