.App {
    padding: 2em;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

a:active {
    text-decoration: none;
    color: white;
}

a:visited {
    text-decoration: none;
    color: white;
}

.App-logo {
    height: 20vmin;
}