.dashboard-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
}

.data-cards-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.wind-data-chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
}

.wave-data-chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 235px;
    margin-top: -30px;
}

.sessions-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}