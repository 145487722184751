.data-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    box-shadow: 1px 1px #d6dae5;

}

.data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: silver;
    width: 100%;
    height: 100%;
}

.value {
    font-size: 2em;
    font-weight: bold;
}

.value-unit {
    font-size: 0.5em;
}

.temperature {
    color: #09273f;
    font-size: 0.7em;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.temperature-low {
    color: deepskyblue;
}

.temperature-water {
    font-size: smaller;
}

.energy {
    width: 100%;
    text-align: center;
    font-size: 0.8em;
}

.energy-very-low {
    color: #930311;
}

.energy-low {
    color: #b05d0a;
    font-weight: bold;
}

.energy-high {
    color: green;
}

.direction {
    font-size: 0.6em;
    padding-left: 0.5em;
}

.station-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e88e5;
    width: 100%;
    height: 50px;
}

.station-name {
    font-size: 0.8em;
}
