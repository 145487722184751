.wind-overview {
    height: 100vh;
    width: 100%;
    padding: 0.5em;
}

.tooltip-container {
    background-color: #4d5080;
    border: solid 1px #9a9dad;
    padding: 0.5em;
    font-weight: bold;
    list-style: none;
}

.tooltip-direction {

}

.tooltip-date {
    font-weight: lighter;
    font-size: smaller;
}

.tooltip-wind-speed {
    font-size: 1.1em;
}
